import { Alert, Box, Container, Drawer, Snackbar } from "@mui/material";
import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createCbCategories,
  deleteCbCategories,
  fetchCbCategorieById,
  fetchCbCategories,
  updateCbCategories,
} from "../../redux/slices/codeBlockSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import OverflowHiddenPage from "../../components/OverFlowhidden";

const formSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  type: Yup.string().trim(),
});
export const DbUiKitsCat = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [updateCategories, setUpdateCategories] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [openCodeBlockCategory, setOpenCodeBlockCategory] = useState(false);
  const [edit, setEditMode] = useState(false);

  const getCBCategoryBySlug = async () => {
    await dispatch(fetchCbCategorieById({ queryParams: editId }))
      .then(unwrapResult)
      .then((data) => {
        setData(data);
      });
  };
  useEffect(() => {
    dispatch(fetchCbCategories())
      .then(unwrapResult)
      .then((result) => {
        // Function to filter out excluded keys from an object
        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };

        // Map the data using the filterObject function
        setUiKits(result?.map(filterObject));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateCategories]);
  // Specify keys to exclude
  const excludeKeys = [
    "image_url",
    "components",
    "slug",
    "__v",
    "updatedAt",
    "componentCount",
  ];
  const headers = [
    { id: "_id", label: "_ID" },
    { id: "name", label: "Name" },
    { id: "type", label: "Type" },
    { id: "createdAt", label: "Created At" },
  ];
  const onDelete = async (id) => {
    await dispatch(
      deleteCbCategories({
        queryParams: id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Code Block Category Deleted!");
        setUpdateCategories(updateCategories + 1);
      });
  };
  const [editId, setEditId] = useState();
  const onEdit = (id) => {
    getCBCategoryBySlug();

    setEditMode(true);
    setOpenCodeBlockCategory(true);
    setEditId(id);
  };
  return (
    <OverflowHiddenPage>
      <Box component={"main"} className="h-[90vh] overflow-auto">
        <Container maxWidth={"xl"}>
          <Snackbar
            open={snack}
            onClose={() => setSnack(false)}
            autoHideDuration={5000}
          >
            <Alert
              onClose={() => setSnack(false)}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
          {/* form for code block category */}
          <Drawer
            open={openCodeBlockCategory}
            anchor="right"
            onClose={() => setOpenCodeBlockCategory(false)}
          >
            <div className="flex  min-h-screen ">
              <div className="w-full md:max-w-lg p-4 md:p-8  rounded-lg ">
                <div className="flex mb-6  justify-between items-center">
                  <h2 className="text-md md:text-2xl font-bold text-center">
                    {edit ? "Edit" : "Create New"} Documentation Category
                  </h2>
                  <div
                    onClick={() => setOpenCodeBlockCategory(false)}
                    className="w-[15px] h-[15px] "
                  >
                    <svg
                      fill="#000000"
                      height="15px"
                      width="15px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 490 490"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    name: data?.name || "",
                    type: data?.type || "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={async (values) => {
                    if (edit) {
                      await dispatch(
                        updateCbCategories({
                          data: values,
                          queryParams: editId,
                        })
                      ).then((data) => {
                        setSnack(true);
                        setMsg("Code Block Category Updated!");
                        setOpenCodeBlockCategory(false);
                        setUpdateCategories(updateCategories + 1);
                      });
                    } else {
                      await dispatch(createCbCategories({ data: values })).then(
                        (data) => {
                          setSnack(true);
                          setMsg("Code Block Category Added!");
                          setOpenCodeBlockCategory(false);
                          setUpdateCategories(updateCategories + 1);
                        }
                      );
                    }
                  }}
                  enableReinitialize={edit}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <Field
                          name="name"
                          type="text"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="type"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Type
                        </label>
                        <Field
                          name="type"
                          type="text"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="type"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="flex items-center justify-start">
                        <button
                          type="submit"
                          className="px-4 py-2 text-white bg-[#4f14e5] rounded-md hover:bg-[#4f14e580] focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Drawer>
          <div className="flex items-center justify-between">
            <h3 className="text-lg md:text-3xl font-bold my-5">
              List Ui Kits Category
            </h3>
            <h6
              onClick={() => {
                setData();
                setEditMode(false);
                setOpenCodeBlockCategory(true);
              }}
              className="font-bold rounded-md cp  text-white"
            >
              <img src="/add.svg" width={30} alt="" />
            </h6>
          </div>
          <TableComponent
            initialData={uiKits || []}
            headers={headers}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Container>
      </Box>
    </OverflowHiddenPage>
  );
};
