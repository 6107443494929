import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { removeToken } from "../redux/slices/userSlice";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Divider,
  Avatar,
} from "@mui/material";
import ScrollToTopButton from "./ScrollTop";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

const HeaderComponent = ({ open, setSidebar }) => {

  const pathname = useLocation().pathname;
  const user = useSelector((state) => state.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box
      sx={{
        width: 258,
        backgroundColor: "#4f46e5",
        color: "white",
        height: "100%",
      }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
      className="shadow-md overflow-hidden"
    >
      <Box sx={{ p: 2, pt: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Developer's Corner
        </Typography>
      </Box>
      <Box
        className="flex flex-col justify-between overflow-y-auto"
        style={{ height: "calc(100vh - 72px)" }}
      >
       
        <Box className="pb-12 md:pb-0">
          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
          <List>
            <div className="ms-auto flex justify-center items-center w-full mt-auto h-full">
              {!user.token ? (
                <>
                  <ListItem>
                    <Link to={"/"}>
                      <img src="./login.svg" width={30} alt="login" />
                    </Link>

                   
                  </ListItem>
                </>
              ) : (
                <ListItem>
                  <Link
                    to={"#"}
                    onClick={() => {
                      dispatch(removeToken());
                      navigate("/");
                    }}
                  >
                    <img src="./logout.png" width={30} alt="logout" />
                  </Link>
                  {user?.role === "admin" ? (
                    <Link to={"/dashboard"} className="ms-4">
                      <img src="./dashboard.svg" width={30} alt="dashboard" />
                    </Link>
                  ) : (
                    ""
                  )}
                
                </ListItem>
              )}
            </div>
          </List>
        </Box>
      </Box>
    </Box>
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const router = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          boxShadow: 3,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Container maxWidth={"xl"}>
          <div className="relative py-4">
            <nav
              className="relative flex items-center justify-between sm:h-10 lg:justify-start"
              aria-label="Global"
            >
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full lg:w-auto">
                  <Link to="/" className="text-2xl font-bold text-[#4f46e5]">
                    Developer's Corner
                  </Link>
                  <div className="-mr-2 flex gap-4 ms-auto items-center lg:hidden">
                    {pathname.includes("/dashboard") ? (
                      <div className="block lg:hidden">
                        <IconButton
                          onClick={() => {
                            open ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          <img
                            width={20}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYElEQVR4nO2ZT2xMURTGvwapLgSl2ogSEoJIWOlorQwLEhsRmqgqIRX/JUgTIdUaQq38DZFogukoFcHSn221HQk2FAkLqU2L0lq0zZM7Gcnk5Dvz7qjk3UnmS+7mvd+55zvv3bn3vjtATtmjMIDXAIYBeAG3YQCvAKzItIhZAH47UIAn2iCAmZkUstcB057SjDdrXXbAsKe0i5kU8lwERxCcIsLLk0yCv4rgGgSnrcLLF9vASeR1ViA4LSd+JtoELiOBxQhOxcRPmU3gNhHUT5g8AAOCeyqYTcSAuZaqZ2R6zSP5+gW3xaaQJhEUJ0wJMdnq80C85HhP1V3ClJB8LwVz2qaQxyIoRpgQMWCm7FTtJEytYK4QJkTy3RHMA5tCPoqgRsJUEgOS20+YfYI5SZhKkk9yb/2KGE/2Vmw81hEDBwRzmDCHBHOQMHUkX41ghgDkpytkMem4HMGrgvhamC6ADZkiBK9pxNe6dAEnBPwD7ui78HY0Hdwq4C7CjE2OUfmE5gruAmHOC2YeYYYAjCN5uwR3M10hbwTcQpg5JLlpUwR3jTBXBTNV6Ws2yRsTTKdWxBjyMdWgfDnKxCPJ+FQ1E66Z5BwhXJjkbRTMT2UXkBgassNqwu0gXC/hWggXJVwf4bYTrppwpayQtQQ0G0ipU4TrJtx9wrUR7j3hIoQrJ9wqVsgRApoxbPOk2y22Ol7ymtQLyzdXZLFTSOiGgMx055q++ezvEmoXUAfcU6fwaD7JfRcc9nqDVlR47JHAdDL+zCovtUCZ949ZrEle8sBP6rjS53zCNhBust/aUEU6WqMk3UXYbsK9I9xupc/VhN3sN7PusfzA0ZJuIOxnwn0i3MYMHk7I76vzksWWw+ickjRscaRExzSAlUqfTYRlW5oz6Q4BzGrL1KYkNd8xflOl1u8Spc97ige5E3iYerNH3DSLFCwOAf62GYQdJJw5eZEqVfqMKx46tF0FO5C7pXTCnrJpBYRlf0eYa1IFSp99iofbpM987UCuHu6qnvhdxM5W2UGaS6oiftebG2fJjaVwV2XagvyI3CiEuyokfs3vBh+UH1s2tTgc+bNztG0ADpj4Xw1BG8gVAvFG2ClGtrVfppDrDhgZbYuaQiYkTwB7HTDk/cNsFXN83cspJ7igP471YgjwopwbAAAAAElFTkSuQmCC"
                            alt="menu"
                          />
                        </IconButton>
                      </div>
                    ) : (
                      ""
                    )}
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerToggle}
                    >
                      <svg
                        className="h-6 w-6 text-gray-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </IconButton>
                  </div>
                </div>
              </div>
             
              <div className="ms-auto flex gap-3 hidden lg:block">
                {!user.token ? (
                  <>
                    <Link to={"/"}>
                      <Button className="!bg-indigo-600" variant="contained">
                        Login
                      </Button>
                    </Link>
                   
                  </>
                ) : (
                  <div className="flex gap-3">
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open1 ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        className="!bg-indigo-600"
                      >
                        <Avatar
                          className="!bg-indigo-600"
                          sx={{ width: 32, height: 32 }}
                        >
                          {user?.name.slice(0, 1)}
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open1}
                      onClose={handleClose}
                      onClick={handleClose}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      {user?.role === "admin" ? (
                        <MenuItem
                          onClick={() => {
                            router("/dashboard");
                            handleClose();
                          }}
                        >
                          <img
                            className="me-2"
                            width={30}
                            height={30}
                            src="/dbd.png"
                          />{" "}
                          Dashboard
                        </MenuItem>
                      ) : (
                        ""
                      )}
                     

                      <Divider />
                      <MenuItem
                        onClick={() => {
                          dispatch(removeToken());
                          router("/");
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <img
                            className="me-2"
                            width={30}
                            height={30}
                            src="/lo.png"
                          />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </Container>
      </Box>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
      <ScrollToTopButton />
    </>
  );
};

export default HeaderComponent;
