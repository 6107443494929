import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, IconButton, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoCamera } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { deleteFile } from "../redux/slices/fileUploadSlice";

const ImageUpload = ({
  id,
  name,
  setFieldValue,
  setMsg,
  setSnack,
  imgSrc,
  setSrc,
  from = "",
}) => {
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [inputKey, setInputKey] = useState(Date.now()); // To reset file input
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  // Initialize image with imgSrc
  useEffect(() => {
    setImage(imgSrc);
    if (from === "direct") {
      setSrc(imgSrc);
    } else {
      setFieldValue("image", imgSrc);
    }
  }, [imgSrc, from, setSrc, setFieldValue]);

  // Handle file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadFile(file);
    }
  };

  // Handle file upload
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        process.env.NODE_ENV === "development"
          ? "http://localhost:4000/api/file/upload"
          : process.env.REACT_APP_API_URL + "/file/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );

      if (response.data) {
        const fileUrl = response.data.data.fileUrl;
        from === "direct" ? setSrc(fileUrl) : setFieldValue("image", fileUrl);
        setSnack(true);
        setMsg("File uploaded successfully!");
        // Reset input key to allow re-upload
        setInputKey(Date.now());
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Handle delete image
  const handleDeleteImage = async () => {
    setImage(null);
    setUploadProgress(0);
    from === "direct" ? setSrc("") : setFieldValue("image", null);
    await dispatch(
      deleteFile({ queryParams: imgSrc.split("uploads/")[1] })
    ).then(() => {
      setSnack(true);
      setMsg("File Deleted!");
      setInputKey(Date.now()); // Reset input key after delete
    });
  };

  return (
    <div className="root">
      {image && (
        <div className="w-[100%]">
          <IconButton
            className="relative w-full"
            onClick={handleDeleteImage}
            color="secondary"
          >
            <div className="bg-white p-3 flex justify-center items-center shadow-lg w-full h-[180px]">
              <img
                src={image}
                className="imagePreview w-[150px] h-[150px]"
                alt="Preview"
              />
            </div>
            <CloseIcon className="absolute top-[20px] right-[20px]" />
          </IconButton>
          <LinearProgress
            variant="determinate"
            className="progress"
            value={uploadProgress}
          />
        </div>
      )}
      <input
        id={id}
        key={inputKey} // This resets the input on file upload
        name={name}
        type="file"
        onChange={handleImageChange}
        className="input"
        hidden
      />
      <label htmlFor="icon-button-file" className="flex justify-center mt-3">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<PhotoCamera />}
        >
          Upload Image
        </Button>
      </label>
    </div>
  );
};

export default ImageUpload;
