import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  TextField,
  TablePagination,
} from "@mui/material";
import TableHeader from "./TableHeader";
import TableRowData from "./TableRowData";

const TableComponent = ({
  initialData,
  headers,
  onDelete,
  onEdit,
  needFile,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to the first page on search
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page on rows per page change
  };

  // Filtering the data based on the search query
  const filteredData = useMemo(() => {
    return initialData.filter((row) =>
      Object.values(row).some(
        (value) =>
          value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) !==
          -1
      )
    );
  }, [initialData, searchQuery]);

  // Sorting data
  const sortedData = useMemo(() => {
    if (orderBy) {
      return filteredData.sort((a, b) => {
        const aValue =
          typeof a[orderBy] === "string"
            ? a[orderBy].toLowerCase()
            : a[orderBy];
        const bValue =
          typeof b[orderBy] === "string"
            ? b[orderBy].toLowerCase()
            : b[orderBy];

        if (typeof aValue === "string" && typeof bValue === "string") {
          // Handle string comparison
          return order === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        // For non-strings (numbers, etc.)
        if (aValue < bValue) return order === "asc" ? -1 : 1;
        if (aValue > bValue) return order === "asc" ? 1 : -1;
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, order, orderBy]);

  // Paginate the data
  const paginatedData = useMemo(() => {
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [sortedData, page, rowsPerPage]);

  return (
    <TableContainer component={Paper} className="p-4 shadow-md">
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearch}
        className="mb-4"
      />
      <Table className="min-w-full bg-white">
        <TableHeader
          headers={headers}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {paginatedData.map((row, index) => {
            return (
              <TableRowData
                key={row._id}
                row={row}
                index={index}
                onEdit={() => onEdit(row._id)}
                onDelete={() => onDelete(needFile ? row.ObjectName : row._id)}
                needFile={needFile}
              />
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default TableComponent;
