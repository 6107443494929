// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchP = createApiThunk({
  name: "fetch-projects",
  method: "GET",
  url: "/projects/all",
});
export const fetchBoughtProjects = createApiThunk({
  name: "fetch-bought-projects",
  method: "GET",
  url: "/projects/orders/my-orders",
});
export const downloadProject = createApiThunk({
  name: "download-bought-projects",
  method: "GET",
  url: "/projects/file",
});
export const createPayment = createApiThunk({
  name: "create-payment",
  method: "POST",
  url: "/orders/payment",
});

export const fetchPbyId = createApiThunk({
  name: "fetch-projects",
  method: "GET",
  url: "/projects",
});
export const fetchPbySlug = createApiThunk({
  name: "fetch-projects",
  method: "GET",
  url: "/projects/slug",
});
export const createproject = createApiThunk({
  name: "create-project",
  method: "POST",
  url: "/projects",
});
export const updateP = createApiThunk({
  name: "update-project",
  method: "PUT",
  url: "/projects",
});
export const deleteP = createApiThunk({
  name: "delete-project",
  method: "DELETE",
  url: "/projects",
});
export const createOrder = createApiThunk({
  name: "create-order",
  method: "POST",
  url: "/orders",
});
export const verifyPayment = createApiThunk({
  name: "verify-payment",
  method: "POST",
  url: "/orders/verifyPayment",
});
const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createproject.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment, decrement } = projectSlice.actions;
export default projectSlice.reducer;
