// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {};

export const createUser = createApiThunk({
  name: "create-user",
  method: "POST",
  url: "/user/register",
});
export const loginUser = createApiThunk({
  name: "login-user",
  method: "POST",
  url: "/user/login",
});
export const refreshTokenNew = createApiThunk({
  name: "refrsh-token",
  method: "POST",
  url: "/user/refreshToken",
});
export const contactus = createApiThunk({
  name: "contact-us",
  method: "POST",
  url: "/contact",
});
export const reviews = createApiThunk({
  name: "reviews",
  method: "POST",
  url: "/review",
});
export const getreviews = createApiThunk({
  name: "reviews",
  method: "GET",
  url: "/review",
});
export const deletereviews = createApiThunk({
  name: "reviews",
  method: "DELETE",
  url: "/review",
});
export const updatereviews = createApiThunk({
  name: "reviews",
  method: "PUT",
  url: "/review",
});
export const getcontactus = createApiThunk({
  name: "contact-us",
  method: "GET",
  url: "/contact",
});
export const updatecontactus = createApiThunk({
  name: "contact-us",
  method: "PUT",
  url: "/contact",
});
export const deletecontactus = createApiThunk({
  name: "contact-us",
  method: "DELETE",
  url: "/contact",
});
export const getOrders = createApiThunk({
  name: "orders",
  method: "GET",
  url: "/orders",
});
export const getUsers = createApiThunk({
  name: "users",
  method: "GET",
  url: "/user/all-users",
});
export const count = createApiThunk({
  name: "users",
  method: "GET",
  url: "/user/get-all/count",
});
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload.refreshToken;
    },
    setUser(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;

      state.mobile = action.payload.mobile;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    removeToken(state, action) {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setToken, removeToken, setRole, setUser, setRefreshToken } =
  userSlice.actions;

export default userSlice.reducer;
