import React, {  useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";

import Dashboard from "./pages/Dashboard/dashboard";
import SideBar from "./components/Dashboard/Sidebar";
import HeaderComponent from "./components/Header";
import { DbUiKitsCat } from "./pages/Dashboard/DbUiKitsCat";
import { DbUiKits } from "./pages/Dashboard/DbUiKits";
import { DbTutorialCategory } from "./pages/Dashboard/DbTutorialCategory";
import { DbTutorials } from "./pages/Dashboard/DbTutorial";
import { DbDocCategory } from "./pages/Dashboard/DbDocCat";
import { DbDocs } from "./pages/Dashboard/DbDocs";
import { DbStorage } from "./pages/Dashboard/DbStorage";

import LoginForm from "./pages/Login";

import PublicRoute from "./utils/PublicRoutes";
import PrivateRoute from "./utils/PrivateRoutes";
import { DbVideos } from "./pages/Dashboard/DbVideo";
import { DbVidCategory } from "./pages/Dashboard/DbVideoCat";
import { DbInterviewCategory } from "./pages/Dashboard/DbIntCat";
import { DbInterview } from "./pages/Dashboard/DbInterview";

import { DbProjectCategory } from "./pages/Dashboard/DbProjectCat";
import { DbProjects } from "./pages/Dashboard/DbProject";

import { DbContact } from "./pages/Dashboard/DbContact";
import { DbTestimonial } from "./pages/Dashboard/DbTestimonials";
import { DbOrders } from "./pages/Dashboard/DbOrders";

const AppRouter = () => {
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);

  return (
    <div
      className={`flex ${
        location.pathname.includes("/dashboard") ? "flex-row" : "flex-col"
      } overflow-hidden`}
    >
      {location.pathname.includes("/dashboard") ? (
        <SideBar open={sidebar} setSidebar={setSidebar} />
      ) : (
        ""
      )}
      <div className="flex flex-col w-full">
        <HeaderComponent open={sidebar} setSidebar={setSidebar} />
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <LoginForm />
              </PublicRoute>
            }
          />
     
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/queries"
            element={
              <PrivateRoute>
                <DbContact />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/testimonials"
            element={
              <PrivateRoute>
                <DbTestimonial />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/orders"
            element={
              <PrivateRoute>
                <DbOrders />
              </PrivateRoute>
            }
          />
         
          <Route
            path="/dashboard/ui-kits-cat"
            element={
              <PrivateRoute>
                <DbUiKitsCat />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/project-cat"
            element={
              <PrivateRoute>
                <DbProjectCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/projects"
            element={
              <PrivateRoute>
                <DbProjects />
              </PrivateRoute>
            }
          />
         
          <Route
            path="/dashboard/ui-kits"
            element={
              <PrivateRoute>
                <DbUiKits />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/tutorials"
            element={
              <PrivateRoute>
                <DbTutorials />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/interviews"
            element={
              <PrivateRoute>
                <DbInterview />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/docs"
            element={
              <PrivateRoute>
                <DbDocs />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/videos"
            element={
              <PrivateRoute>
                <DbVideos />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/storage"
            element={
              <PrivateRoute>
                <DbStorage />
              </PrivateRoute>
            }
          />
          

          <Route
            path="/dashboard/tutorial-category"
            element={
              <PrivateRoute>
                <DbTutorialCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/video-category"
            element={
              <PrivateRoute>
                <DbVidCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/interview-category"
            element={
              <PrivateRoute>
                <DbInterviewCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/documentation-category"
            element={
              <PrivateRoute>
                <DbDocCategory />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      {!location.pathname.includes("/dashboard") ? <Footer /> : ""}
    </div>
  );
};

export default AppRouter;
