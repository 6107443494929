// apiUtils.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  timeout: 10000, // Timeout of 10 seconds
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${""}`,
    // Add any default headers here
  },
});
export const setAuthToken = (token) => {
  console.log(token, "yes");

  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    axiosInstance.defaults.headers.Authorization = "Bearer " + token;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
    delete axiosInstance.defaults.headers.Authorization;
  }
};

// Enhanced createApiThunk function with customizable options
export const createApiThunk = ({
  name,
  method = "GET",
  url,
  contentType = null,

  headers = {},
  queryParams = {},
  data = null,
  authToken = null,
  onRequestFulfilled = null,
  onRequestRejected = null,
}) => {
  return createAsyncThunk(`api/${name}`, async (options = {}, thunkAPI) => {
    try {
      let response;

      const config = {
        method: options.method || method,
        url: `${options.url || url}/${
          options.queryParams ? options.queryParams : ""
        }`,
        headers: {
          "Content-Type": "application/json",
          ...headers,
          ...options.headers,
        },
      };
      if (data) {
        config.data = `${options.data ? options.data : ""}`;
      }
      // Handle different content types
      if (contentType === "multipart/form-data") {
        // For file uploads (multipart/form-data)
        const formData = new FormData();
        for (let key in options.data) {
          formData.append(key, options.data[key]);
        }
        config.headers["Content-Type"] = "multipart/form-data";

        config.data = formData;
      } else {
        // For JSON data
        config.headers["Content-Type"] = "application/json";
        if (options.data) {
          config.data = options.data;
        }
      }
      // Add authorization token if provided
      if (options.authToken || authToken) {
        config.headers["Authorization"] = `Bearer ${
          options.authToken || authToken
        }`;
      }

      // Apply interceptors if provided
      if (onRequestFulfilled || onRequestRejected) {
        axiosInstance.interceptors.response.use(
          (response) =>
            onRequestFulfilled
              ? onRequestFulfilled(response, thunkAPI)
              : response,
          (error) =>
            onRequestRejected
              ? onRequestRejected(error, thunkAPI)
              : Promise.reject(error)
        );
      }

      response = await axiosInstance(config);

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  });
};
