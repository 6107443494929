import "./App.css";
import AppRouter from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance, setAuthToken } from "./redux/apiUtils";
import { useEffect } from "react";
import { logOut } from "./utils/functions";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  refreshTokenNew,
  setRefreshToken,
  setToken,
} from "./redux/slices/userSlice";
let show = true;
let isRefreshing = false;
let failedQueue = [];
function App() {
  // Add a request interceptor
  const dispatch = useDispatch();

  const selectorToken = useSelector((state) => state.user?.token);
  const refreshToken = useSelector((state) => state.user?.refreshToken);
  if (selectorToken) {
    axiosInstance.interceptors.request.use(setAuthToken(selectorToken));
  }
  useEffect(() => {
    axiosInstance.interceptors.request.use(setAuthToken(selectorToken));
  }, [selectorToken]);
  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (req) => {
        return req;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };
    axiosInstance.interceptors.response?.use(
      function (response) {
        if (show) {
          if (response && response?.data?.status_code === 401) {
            /*  logOut(dispatch); */
            console.log("under respose line 55");
          }
          if (response && response?.data?.status) {
          }

          if (response && !response?.data?.status) {
            if (response?.data?.message) {
            }
          }
        }

        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        // await mutex.waitForUnlock();
        if (!selectorToken) {
          /*   window.location.href = "/login"; */
          console.log("72");
        }
        if (error?.response?.status === 403) {
          /*  logOut(dispatch); */
          console.log("under respose line 76");

          return;
        }
        if (
          selectorToken &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                return axiosInstance(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function (resolve, reject) {
            axiosInstance.defaults.headers.common.Authorization =
              "Bearer " + refreshToken;
            axiosInstance.defaults.headers.Authorization =
              "Bearer " + refreshToken;
            dispatch(refreshTokenNew({ data: { refreshToken: refreshToken } }))
              .then(unwrapResult)
              .then((result) => {
                if (result) {
                  console.log("under respose line 112");
                }
                /*  if (result === 401) {
                  logOut(dispatch);
                } */
                axiosInstance.defaults.headers.common["Authorization"] =
                  "Bearer " + result.token;
                axiosInstance.defaults.headers.Authorization =
                  "Bearer " + result.token;
                originalRequest.headers["Authorization"] =
                  "Bearer " + result.token;
                originalRequest.headers.Authorization =
                  "Bearer " + result.token;
                processQueue(null, result.token);
                dispatch(setToken(result.token));
                dispatch(setRefreshToken(result.refreshToken));

                setAuthToken(result?.token);
                resolve(axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        // else if (error?.response?.status === 401) {
        // 	logOut(dispatch, history);
        // }

        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
