import React, { useEffect } from "react";

const OverflowHiddenPage = ({ children }) => {
  useEffect(() => {
    // Set overflow: hidden when component mounts
    if (window.innerWidth > 1200) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup: Reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return <>{children}</>;
};

export default OverflowHiddenPage;
