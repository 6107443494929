import { Alert, Box, Container, Drawer, Snackbar } from "@mui/material";
import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ImageUpload from "../../components/Upload";

import OverflowHiddenPage from "../../components/OverFlowhidden";
import {
  deletereviews,
  getreviews,
  reviews,
  updatereviews,
} from "../../redux/slices/userSlice";

// Updated form schema
const formSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  comment: Yup.string().trim().required("Comment is required"),
  image: Yup.string().trim(),
});

export const DbTestimonial = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [openCodeBlock, setOpenCodeBlock] = useState(false);
  const [edit, setEditMode] = useState(false);
  const editor = useRef(null);

  useEffect(() => {
    dispatch(getreviews())
      .then(unwrapResult)
      .then((result) => {
        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };
        setUiKits(result?.review?.map(filterObject));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateCodeBlocks]);

  const excludeKeys = [
    "image_url",
    "slug",
    "__v",
    "updatedAt",
    "componentCount",
    "image",
    "code",
    "category",
    "language",
  ];

  const headers = [
    { id: "_id", label: "_ID" },
    { id: "name", label: "Name" },
    { id: "comment", label: "Comment" },
    { id: "createdAt", label: "Created At" },
  ];

  const onDelete = async (id) => {
    await dispatch(deletereviews({ queryParams: id }))
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Review Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };

  const [editId, setEditId] = useState();
  const getICById = async (editId) => {
    await dispatch(getreviews({ queryParams: editId }))
      .then(unwrapResult)
      .then((data) => {
        setData(data.review);
      });
  };

  const onEdit = (id) => {
    getICById(id);
    setEditMode(true);
    setOpenCodeBlock(true);
    setEditId(id);
  };

  return (
    <OverflowHiddenPage>
      <Box component={"main"} className="overflow-auto h-[90vh]">
        <Container maxWidth={"xl"}>
          <Snackbar
            open={snack}
            onClose={() => setSnack(false)}
            autoHideDuration={5000}
          >
            <Alert
              onClose={() => setSnack(false)}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
          <Drawer
            open={openCodeBlock}
            anchor="right"
            onClose={() => setOpenCodeBlock(false)}
          >
            <div className="flex">
              <div className="w-full md:w-[600px] p-4 md:p-8 rounded-lg">
                <div className="flex mb-6 justify-between items-center">
                  <h2 className="text-md md:text-2xl font-bold text-center">
                    {edit ? "Edit Review" : "Create New Review"}
                  </h2>
                  <div
                    onClick={() => setOpenCodeBlock(false)}
                    className="w-[15px] h-[15px] cursor-pointer"
                  >
                    <svg
                      fill="#000000"
                      height="15px"
                      width="15px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 490 490"
                    >
                      <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337"></polygon>
                    </svg>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    name: data?.name || "",
                    comment: data?.comment || "",
                    image: data?.image || "",
                  }}
                  validationSchema={formSchema}
                  enableReinitialize={edit}
                  onSubmit={async (values) => {
                    if (edit) {
                      await dispatch(
                        updatereviews({ data: values, queryParams: editId })
                      ).then((data) => {
                        setSnack(true);
                        setMsg("Review Updated!");
                        setOpenCodeBlock(false);
                        setUpdateCodeBlocks(updateCodeBlocks + 1);
                      });
                    } else {
                      await dispatch(reviews({ data: values })).then((data) => {
                        setSnack(true);
                        setMsg("Review Added!");
                        setOpenCodeBlock(false);
                        setUpdateCodeBlocks(updateCodeBlocks + 1);
                      });
                    }
                  }}
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form>
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <Field
                          name="name"
                          type="text"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="comment"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Comment
                        </label>
                        <Field
                          name="comment"
                          as="textarea"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="comment"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="image"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Image
                        </label>
                        <ImageUpload
                          id="icon-button-file"
                          name="image"
                          setFieldValue={setFieldValue}
                          setSnack={setSnack}
                          setMsg={setMsg}
                          imgSrc={data?.image}
                        />
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="flex items-center justify-start">
                        <button
                          type="submit"
                          className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          {edit ? "Edit" : "Add"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Drawer>
          <div className="flex items-center justify-between">
            <h3 className="text-lg md:text-3xl font-bold my-5">
              List of Testimonials
            </h3>
            <h6
              onClick={() => {
                setData();
                setEditMode(false);
                setOpenCodeBlock(true);
              }}
              className="font-bold rounded-md cursor-pointer text-white"
            >
              <img src="/add.svg" width={30} alt="Add" />
            </h6>
          </div>
          <TableComponent
            initialData={uiKits || []}
            headers={headers}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Container>
      </Box>
    </OverflowHiddenPage>
  );
};
