import { Alert, Box, Container, Drawer, Snackbar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import {
  createDocCat,
  deleteDC,
  fetchDCbyId,
  updateDC,
} from "../../redux/slices/docCatSlice";
import OverflowHiddenPage from "../../components/OverFlowhidden";
import TableComponent from "../../components/Table/TableComponent";
import {
  deletecontactus,
  getcontactus,
  updatecontactus,
} from "../../redux/slices/userSlice";

const formSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  mobile: Yup.string().trim().required("Mobile is required"),
  subject: Yup.string().trim().required("Subject is required"),
  profession: Yup.string().trim().required("Profession is required"),
  comment: Yup.string().trim().required("Comment is required"),
  status: Yup.string().trim().required("Status is required"),
  reply: Yup.string().trim().required("Reply is required"),
});

export const DbContact = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [openCodeBlock, setOpenCodeBlock] = useState(false);
  const [edit, setEditMode] = useState(false);
  const editor = useRef(null);
  const [replyContent, setReplyContent] = useState(""); // For Jodit editor content

  useEffect(() => {
    dispatch(getcontactus())
      .then(unwrapResult)
      .then((result) => {
        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };
        setUiKits(result?.contact?.map(filterObject));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateCodeBlocks]);

  const excludeKeys = [
    "message",
    "slug",
    "__v",
    "updatedAt",
    "comment",
    "subject",
    "profession",
    "reply",
  ];
  const headers = [
    { id: "_id", label: "_ID" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "mobile", label: "Mobile" },
    { id: "status", label: "Status" },
    { id: "createdAt", label: "Created At" },
  ];

  const onDelete = async (id) => {
    await dispatch(deletecontactus({ queryParams: id }))
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Query Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };

  const [editId, setEditId] = useState();
  const getDC = async (editId) => {
    await dispatch(getcontactus({ queryParams: editId }))
      .then(unwrapResult)
      .then((data) => {
        setData(data.contact);
        setReplyContent(data.contact.reply || ""); // Set initial content for Jodit editor
      });
  };

  const onEdit = (id) => {
    getDC(id);
    setEditMode(true);
    setOpenCodeBlock(true);
    setEditId(id);
  };

  return (
    <OverflowHiddenPage>
      <Box component={"main"} className="overflow-auto h-[90vh]">
        <Container maxWidth={"xl"}>
          <Snackbar
            open={snack}
            onClose={() => setSnack(false)}
            autoHideDuration={5000}
          >
            <Alert
              onClose={() => setSnack(false)}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>

          <Drawer
            open={openCodeBlock}
            anchor="right"
            onClose={() => setOpenCodeBlock(false)}
          >
            <div className="flex">
              <div className="w-full md:w-[600px] p-4 md:p-8 rounded-lg">
                <div className="flex mb-6 justify-between items-center">
                  <h2 className="text-md md:text-2xl font-bold text-center">
                    Update Querie
                  </h2>
                  <div
                    onClick={() => setOpenCodeBlock(false)}
                    className="w-[15px] h-[15px]"
                  >
                    <svg
                      fill="#000000"
                      height="15px"
                      width="15px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 490 490"
                    >
                      <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>
                    </svg>
                  </div>
                </div>

                <Formik
                  initialValues={{
                    name: data?.name || "",
                    email: data?.email || "",
                    mobile: data?.mobile || "",
                    subject: data?.subject || "",
                    profession: data?.profession || "",
                    comment: data?.comment || "",
                    status: data?.status || "Submitted",
                    reply: data?.reply || "",
                  }}
                  validationSchema={formSchema}
                  enableReinitialize={edit}
                  onSubmit={async (values) => {
                    values.reply = replyContent; // Set the reply content from the Jodit editor
                    if (edit) {
                      await dispatch(
                        updatecontactus({
                          data: {
                            status: values?.status,
                            reply: values?.reply,
                          },
                          queryParams: editId,
                        })
                      ).then(() => {
                        setSnack(true);
                        setMsg("Query Updated!");
                        setOpenCodeBlock(false);
                        setUpdateCodeBlocks(updateCodeBlocks + 1);
                      });
                    }
                  }}
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form>
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <Field
                          name="name"
                          type="text"
                          disabled={true}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          disabled={true}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="mobile"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Mobile
                        </label>
                        <Field
                          name="mobile"
                          type="text"
                          disabled={true}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="subject"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Subject
                        </label>
                        <Field
                          name="subject"
                          type="text"
                          disabled={true}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="profession"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Profession
                        </label>
                        <Field
                          name="profession"
                          type="text"
                          disabled={true}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="profession"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="comment"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Comment
                        </label>
                        <Field
                          name="comment"
                          type="text"
                          disabled={true}
                          className="w-full  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="comment"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="status"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Status
                        </label>
                        <Field
                          name="status"
                          as="select"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        >
                          <option value="Submitted">Submitted</option>
                          <option value="In Review">In Review</option>
                          <option value="Resolved">Resolved</option>
                        </Field>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="reply"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Reply
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={replyContent}
                          onBlur={(newContent) => {
                            setFieldValue("reply", newContent);
                            setReplyContent(newContent);
                          }}
                          config={{ height: 200 }} // Adjust the editor height as needed
                        />
                        <ErrorMessage
                          name="reply"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>

                      <div className="flex items-center justify-start">
                        <button
                          type="submit"
                          className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Update
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Drawer>

          <div className="flex items-center justify-between">
            <h3 className="text-lg md:text-3xl font-bold my-5">List Queries</h3>
          </div>

          <TableComponent
            initialData={uiKits || []}
            headers={headers}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Container>
      </Box>
    </OverflowHiddenPage>
  );
};
